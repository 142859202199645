import React, { Component } from "react";
import PageHeader from "../../Include/PageHeader";
import axios from "axios";
import Loading from "../../component/Loading";
import { Link } from "@reach/router";
import Helper from "../../Helper";
import SEO from "../../component/SEO";

const defaultPage = 1;
const defaultLimit = 20;
export default class Reviews extends Component {
  constructor() {
    super();
    this.state = {
      is_loading: true,
      list: [],
      page: defaultPage,
      limit: defaultLimit,
      pagination: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    // if (this.state.data) { return; }
    let params = {
      page: this.state.page,
      limit: this.state.limit,
    };
    // let resTest = await fetch("https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJAfKXcpKd4jARIHsEFpbQ4lg&key=AIzaSyAr2E3_UWtUVOrEDThX2J_sKrL6R7mkXmA", {
    let res = await fetch(
      "https://places.googleapis.com/v1/places/ChIJAfKXcpKd4jARIHsEFpbQ4lg?fields=reviews,displayName&key=AIzaSyCWhF4JJU5jfmilRFvvY7Dw1t4uYrt_YzQ"
    ).then((response) => response.json());
    // console.log("resTest", resTest)
    // let res = await axios.get(`${window.api_host}/web/reviews?${window.$.param(params)}`);
    // console.log(res)
    let data = [...this.state.list];
    let pagination = false;
    data = res.reviews || [];
    // if (res.data.success) {
    //     data = data.concat(res.data.data);
    //     pagination = res.data.pagination;
    // }
    this.setState(
      {
        is_loading: false,
        list: data,
        pagination: pagination,
      },
      () => {
        this.viewMore?.html("ดูเพิ่ม");
        this.viewMore?.removeClass("loading");
      }
    );
  }

  render() {
    return (
      <>
        <SEO />
        <div style={{ position: "relative" }}>
          <PageHeader title={"รีวิว จากลูกค้า"} />
          <section className="new-cars pt-4">
            <div className="container mt-3" style={{ position: "relative" }}>
              {this.state.is_loading ? (
                <div style={{ paddingTop: 100, paddingBottom: 120 }}>
                  <Loading />
                </div>
              ) : null}
              {!this.state.is_loading && this.state.list.length > 0 ? (
                <>
                  <div className="row user-review-list justify-content-center align-items-center">
                    {this.state.list.map((item, key) => {
                      let starempty = [];
                      let starfilled = [];
                      if (item.rating) {
                        for (let index = 0; index < item.rating; index++) {
                          starfilled.push(1);
                        }
                        for (let index = 0; index < 5 - item.rating; index++) {
                          starempty.push(1);
                        }
                      }
                      return (
                        <React.Fragment key={key}>
                          <div className="col-12 col-md-6 col-lg-4 mb-5 user-review-item">
                            <a
                              to={`/review/${item.id}`}
                              href={item.authorAttribution?.uri}
                              target="_blank"
                            >
                              <div className="review-card custom-hover">
                                <div className="review-body">
                                  <div className="d-flex justify-content-center align-items-center review-icon mb-3">
                                    <i className="fa-solid fa-comment-dots"></i>
                                  </div>
                                  <h3>{item.authorAttribution?.displayName}</h3>
                                  <small className="mb-2">
                                    {item.relativePublishTimeDescription}
                                  </small>
                                  <p>{item.originalText?.text}</p>
                                  <div className="review-pos">
                                    <div className="d-flex justify-content-center align-items-center mb-2">
                                      {starfilled.map((i, findex) => {
                                        return (
                                          <i
                                            className="las la-star full"
                                            key={findex}
                                          ></i>
                                        );
                                      })}
                                      {starempty.map((i, eindex) => {
                                        return (
                                          <i
                                            className="las la-star empty"
                                            key={eindex}
                                          ></i>
                                        );
                                      })}
                                    </div>
                                    <div className="image-frame">
                                      <img
                                        src={
                                          item.authorAttribution?.photoUri ||
                                          `${window.origin}/assets/media/avatars/blank.png`
                                        }
                                        className="image"
                                        alt={`reviewer`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {!this.state.is_loading && this.state.list.length == 0 ? (
                <>
                  <h4
                    className="text-center mt-5 text-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    ไม่พบข้อมูล
                  </h4>
                </>
              ) : null}
              {!this.state.is_loading &&
              this.state.pagination &&
              this.state.pagination.now_page < this.state.pagination.pages ? (
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  {/* <Link to="/" className='button-53'>ดูทั้งหมด</Link> */}
                  <button
                    ref={(ref) => {
                      this.viewMore = window.$(ref);
                      this.viewMore.off().on("click", () => {
                        if (this.viewMore.hasClass("loading")) return;
                        this.viewMore.addClass("loading");
                        this.viewMore.html("กำลังโหลดข้อมูล...");
                        let page = this.state.pagination.now_page + 1;
                        this.setState({ page: page }, () => {
                          this.fetch();
                        });
                      });
                    }}
                    className="button-53"
                  >
                    ดูเพิ่ม
                  </button>
                </div>
              ) : null}
              <div className="d-flex justify-content-center align-items-center mt-4">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/ChampEarth+Motors/@13.9452007,100.5794258,17z/data=!4m18!1m9!3m8!1s0x30e29d927297f201:0x58e2d09616047b20!2sChampEarth+Motors!8m2!3d13.9451955!4d100.5820007!9m1!1b1!16s%2Fg%2F11hs17yhyb!3m7!1s0x30e29d927297f201:0x58e2d09616047b20!8m2!3d13.9451955!4d100.5820007!9m1!1b1!16s%2Fg%2F11hs17yhyb?authuser=0&entry=ttu"
                  className="button-53"
                >
                  ดูรีวิวทั้งหมดของ Google Map{" "}
                  <i class="fa-solid fa-map-location-dot"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
