import React, { Component } from "react";
import PageHeader from "../../Include/PageHeader";
import axios from "axios";
import Loading from "../../component/Loading";
import Cube from "../../Animation/Cube";
import Helper from "../../Helper";
import { Link } from "@reach/router";
import SEO from "../../component/SEO";

export default class ReviewDetail extends Component {
  constructor() {
    super();
    this.state = {
      is_loading: true,
      data: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    let res = await axios.get(
      `${window.api_host}/web/reviews/${this.props.id}`
    );
    console.log(res);
    let data = false;
    if (res.data.success) {
      data = res.data.data;
    }
    this.setState({
      is_loading: false,
      data: data,
    });
  }

  render() {
    let item = this.state.data;

    let starempty = [];
    let starfilled = [];
    if (item.star) {
      for (let index = 0; index < item.star; index++) {
        starfilled.push(1);
      }
      for (let index = 0; index < 5 - item.star; index++) {
        starempty.push(1);
      }
    }
    return (
      <>
        <SEO />
        <div style={{ position: "relative" }}>
          <PageHeader />
          {item ? (
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb custom-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/reviews">บทความ</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    รีวิวจากคุณ {item.name}
                  </li>
                </ol>
              </nav>
            </div>
          ) : null}
          <section className="new-cars mt-5" style={{ overflow: "hidden" }}>
            {this.state.is_loading ? (
              <div style={{ paddingTop: 100, paddingBottom: 120 }}>
                <Loading />
              </div>
            ) : null}
            {!this.state.is_loading && item ? (
              <>
                <div className="container user-review-detail">
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-2 mb-3">
                      <div className="image-frame">
                        <img
                          src={
                            item?.fileImage?.imagePath ||
                            `${window.origin}/assets/media/avatars/blank.png`
                          }
                          className="image"
                          alt={`reviewer`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-9 col-lg-10 pb-5">
                      <h3 style={{ fontWeight: "bold" }}>{item.name}</h3>
                      {item.reviewDate ? (
                        <p className="text-muted">
                          {Helper.getDate(item.reviewDate)}
                        </p>
                      ) : null}
                      <div className="mb-3">
                        {starfilled.map((i, findex) => {
                          return (
                            <i className="las la-star full" key={findex}></i>
                          );
                        })}
                        {starempty.map((i, eindex) => {
                          return (
                            <i className="las la-star empty" key={eindex}></i>
                          );
                        })}
                      </div>
                      <p className="mb-0" style={{ fontSize: 20 }}>
                        {item.description}
                      </p>

                      {item.moreReviewImages &&
                      item.moreReviewImages.length > 0 ? (
                        <>
                          <hr className="mt-3 mb-3" />
                          <p>รูปภาพรีวีว</p>
                          <div className="row">
                            {item.moreReviewImages.map((image, index) => {
                              return (
                                <div className="col-4 col-md-2" key={index}>
                                  <a href={image?.imagePath} data-fancybox>
                                    <div className="image-frame">
                                      <img
                                        src={
                                          image?.imagePath ||
                                          `${window.origin}/assets/media/avatars/blank.png`
                                        }
                                        className="image"
                                        alt={`reviewer`}
                                      />
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                <Cube />
              </>
            ) : null}
          </section>
        </div>
      </>
    );
  }
}
