import React from "react";
import { Router } from "@reach/router";
//INCLUDE
import Header from "./Include/Header";
import Footer from "./Include/Footer";
//PAGES
import Home from "./Page/Home/Home";
import Cars from "./Page/Cars/Cars";
import CarDetails from "./Page/Cars/CarDetails";
import BlogsList from "./Page/Blogs/BlogsList";
import BlogsDetail from "./Page/Blogs/BlogsDetail";
import SaleCarPhone from "./Page/SaleCar/SaleCarPhone";
import SaleCarRequest from "./Page/SaleCar/SaleCarRequest";
import SaleCarOTP from "./Page/SaleCar/SaleCarOTP";
import AboutUs from "./Page/AboutUs/AboutUs";
import Policy from "./Page/Policy/Policy";
import Terms from "./Page/Terms/Terms";
import ContactUs from "./Page/ContactUs/ContactUs";
import FABs from "./Include/FABs";
import Reviews from "./Page/Reviews/Reviews";
import ReviewDetail from "./Page/Reviews/ReviewDetail";

let is_emulators = false;
if (window.location.origin.indexOf("localhost:") !== -1) {
  is_emulators = true;
}
window.api_host =
  "https://asia-southeast1-champearthmotor-csi.cloudfunctions.net/app";
if (is_emulators) {
  window.api_host =
    "http://localhost:5001/champearthmotor-csi/asia-southeast1/app";
}
export default class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Router>
          <Home path="/" />
          <AboutUs path="/about" />
          <ContactUs path="/contact" />
          <Terms path="/terms" />
          <Policy path="/policy" />
          <BlogsList path="/blogs" />
          <BlogsDetail path="/blog/:uid" />
          <Cars path="/cars" />
          <CarDetails path="/car/:id" />
          <SaleCarPhone path="/sale/car/login" />
          <SaleCarOTP path="/sale/car/confirm-otp" />
          <SaleCarRequest path="/sale/car" />
          <Reviews path="/reviews" />
          <ReviewDetail path="/review/:id" />
        </Router>
        <Footer />
        <FABs />
      </>
    );
  }
  
}
