import React, { Component } from "react";
import Helper from "../../Helper";
import CarCard from "./../../component/CarCard";
import CarFilter from "./include/CarFilter";
import CarBrandSelector from "./include/CarBrandSelector";
import axios from "axios";
import Loading from "../../component/Loading";
import { navigate } from "@reach/router";
import SEO from "../../component/SEO";

const default_limit = 12;
export default class Cars extends Component {
  constructor() {
    super();

    this.state = {
      page: Helper.getParameterByName("page")
        ? Helper.getParameterByName("page")
        : 1,
      is_loading: true,
      is_loading_menu: true,
      list: [],
      all_list: [],
      qs: {
        page: Helper.getParameterByName("page")
          ? Helper.getParameterByName("page")
          : 1,
        ...this.getParams(),
      },
      options: false,
      selectorOptions: false,
    };
    window.CarSelector = React.createRef();
  }
  getParams() {
    let qs = {};
    if (Helper.getParameterByName("commissionNo")) {
      qs.commissionNo = Helper.getParameterByName("commissionNo");
    }
    if (Helper.getParameterByName("brand")) {
      qs.brand = Helper.getParameterByName("brand");
    }
    if (Helper.getParameterByName("model")) {
      qs.model = Helper.getParameterByName("model");
    }
    if (Helper.getParameterByName("minor")) {
      qs.minor = Helper.getParameterByName("minor");
    }
    if (Helper.getParameterByName("submodel")) {
      qs.submodel = Helper.getParameterByName("submodel");
    }
    if (Helper.getParameterByName("statusId")) {
      qs.statusId = Helper.getParameterByName("statusId");
    }
    if (Helper.getParameterByName("purchaseDate")) {
      qs.purchaseDate = Helper.getParameterByName("purchaseDate");
    }
    if (Helper.getParameterByName("color")) {
      qs.color = Helper.getParameterByName("color");
    }
    if (Helper.getParameterByName("isReserve")) {
      qs.isReserve = Helper.getParameterByName("isReserve");
    }
    if (Helper.getParameterByName("priceStart")) {
      qs.priceStart = Helper.getParameterByName("priceStart");
    }
    if (Helper.getParameterByName("priceEnd")) {
      qs.priceEnd = Helper.getParameterByName("priceEnd");
    }
    if (Helper.getParameterByName("priceMax")) {
      qs.priceMax = Helper.getParameterByName("priceMax");
    }
    if (Helper.getParameterByName("priceMin")) {
      qs.priceMin = Helper.getParameterByName("priceMin");
    }
    if (Helper.getParameterByName("orderBy")) {
      qs.orderBy = Helper.getParameterByName("orderBy");
    }
    return qs;
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState(
        {
          is_loading: true,
          list: [],
          qs: {
            page: Helper.getParameterByName("page")
              ? Helper.getParameterByName("page")
              : 1,
            ...this.getParams(),
          },
        },
        () => {
          this.fetch();
        }
      );
    }
    if (
      this.props.location.search !== prevProps.location.search &&
      !this.props.location.search
    ) {
      this.setState(
        {
          is_loading: true,
          list: [],
          qs: {
            page: 1,
            limit: default_limit,
          },
        },
        () => {
          this.fetch();
        }
      );
    }
  }

  componentDidMount() {
    this.fetch();
    this.getOptions();
  }
  async getOptions() {
    let options = this.state.options;
    if (!this.state.options) {
      let res = await axios.get(`${window.api_host}/web/options`);
      // console.log(res)
      if (!options && res.data.success) {
        options = res.data.options;
      }
    }
    let selectorList = [];
    let selectedBrandUid = false;
    let selectedgetModelUid = false;
    let selectedgetMinorUid = false;
    let selectedType = false;
    if (this.state.qs.brand) {
      let getBrand = options.brand.filter(
        (e) => e.name == this.state.qs.brand
      )[0];
      if (getBrand) {
        selectedBrandUid = getBrand.id;
        if (!this.state.qs.model && !this.state.qs.minor) {
          selectedType = `model`;
          selectorList = options.model.filter(
            (e) => e.brandUid == selectedBrandUid
          );
        }
      }
    }
    if (this.state.qs.brand && this.state.qs.model) {
      let getModel = options.model.filter(
        (e) => e.name == this.state.qs.model
      )[0];
      if (getModel) {
        selectedgetModelUid = getModel.id;
        if (!this.state.qs.minor) {
          selectedType = `minor`;
          selectorList = options.minor.filter(
            (e) => e.modelUid == selectedgetModelUid
          );
        }
      }
    }
    if (this.state.qs.brand && this.state.qs.model && this.state.qs.minor) {
      let getMinor = options.minor.filter(
        (e) => e.name == this.state.qs.minor
      )[0];
      if (getMinor) {
        selectedgetMinorUid = getMinor.id;
        if (!this.state.qs.submodel) {
          selectedType = `submodel`;
        }
        selectorList = options.submodel.filter(
          (e) => e.minorUid == selectedgetMinorUid
        );
      }
    }
    this.setState(
      {
        is_loading_menu: false,
        options: options,
        selectorList: selectorList,
        selectedType: selectedType,
      },
      () => {
        this.viewMore?.html("ดูเพิ่ม");
        this.viewMore?.removeClass("loading");
        let type = window.localStorage.getItem("carsListType") || "grip";
        // console.log("CarHeader componentDidUpdate", type)
        if (type == "list") {
          window.$("#car-list").addClass("list");
        } else {
          window.$("#car-list").removeClass("list");
        }
        window.$(".btn-toggle-menu-container").show();
      }
    );
  }
  async fetch() {
    let qs = {};
    let list = [...this.state.list];
    let all_list = this.state.all_list;
    if (this.state.all_list.length == 0) {
      let res = await axios.get(
        `${window.api_host}/web/cars?${window.$.param(qs)}`
      );
      if (res.data.success) {
        // list = list.concat(res.data.data);
        // list = res.data.data;
        all_list = res.data.data;
        // pagination = res.data.pagination;
      }
    }

    // console.log(`selectedBrandUid`, selectedBrandUid)
    // console.log(`selectorList`, selectorList)
    // console.log(`selectedType`, selectedType)

    list = this.filterCars(all_list);

    this.setState({
      is_loading: false,
      list: list,
      all_list: all_list,
    });
  }

  filterCars(all_list) {
    let qs = this.state.qs;
    // console.log('filterCars', qs)
    let list = [...all_list];
    if (qs.brand) {
      list = list.filter((e) => e.brand == qs.brand);
    }
    if (qs.model) {
      list = list.filter((e) => e.model == qs.model);
    }
    if (qs.minor) {
      list = list.filter((e) => e.minor == qs.minor);
    }
    if (qs.submodel) {
      list = list.filter((e) => e.submodel == qs.submodel);
    }
    if (qs.color) {
      list = list.filter((e) => e.color == qs.color);
    }
    if (qs.isReserve) {
      list = list.filter((e) => e.isReserve == true);
    }
    if (qs.priceMax && qs.priceMin) {
      list = list.filter(
        (e) =>
          e.sellingPrice <= parseFloat(qs.priceMax) &&
          e.sellingPrice >= parseFloat(qs.priceMin)
      );
    }
    let orderBy = qs.orderBy?.split(",") || ["purchaseDateTime", "desc"];
    list.sort((a, b) => {
      let aValue = parseFloat(a[`${orderBy[0]}`]);
      let bValue = parseFloat(b[`${orderBy[0]}`]);
      if (aValue < bValue) {
        return orderBy[1] == "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderBy[1] == "asc" ? 1 : -1;
      }
      return 0;
    });
    return list;
  }

  render() {
    return (
      <>
        <SEO />
        <div style={{ position: "relative" }}>
          <PageHeader qs={this.state.qs} />
          <section className="ourscars pt-0">
            {!this.state.is_loading ? (
              <CarHeader qs={this.state.qs} options={this.state.options} />
            ) : null}
            <div
              className="container mt-3"
              style={{ position: "relative", minHeight: 760 }}
            >
              {!this.state.is_loading && this.state.list.length > 0 ? (
                <CarBrandSelector
                  qs={this.state.qs}
                  selectorList={this.state.selectorList}
                  selectedType={this.state.selectedType}
                />
              ) : null}
              {this.state.options ? (
                <CarFilterBox options={this.state.options} qs={this.state.qs} />
              ) : null}
              {this.state.is_loading ? (
                <div style={{ paddingTop: 100, paddingBottom: 120 }}>
                  <Loading />
                </div>
              ) : null}
              {!this.state.is_loading && this.state.list.length > 0 ? (
                <>
                  <div className="row" id="car-list">
                    {this.state.list.map((item, key) => {
                      return (
                        <React.Fragment key={`${item.commissionNo}-${item.id}`}>
                          <div className="pl-3 pr-3 mb-3 mt-3">
                            <CarCard data={item} />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {!this.state.is_loading && this.state.list.length == 0 ? (
                <>
                  <h4
                    className="text-center mt-5 text-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    ไม่พบข้อมูล
                  </h4>
                </>
              ) : null}
              {!this.state.is_loading &&
              this.state.pagination &&
              this.state.pagination.now_page < this.state.pagination.pages ? (
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  {/* <Link to="/" className='button-53'>ดูทั้งหมด</Link> */}
                  <button
                    ref={(ref) => {
                      this.viewMore = window.$(ref);
                      this.viewMore.off().on("click", () => {
                        if (this.viewMore.hasClass("loading")) return;
                        this.viewMore.addClass("loading");
                        this.viewMore.html("กำลังโหลดข้อมูล...");
                        let qs = this.state.qs;
                        qs.page = this.state.pagination.now_page + 1;
                        this.setState({ qs: qs }, () => {
                          this.fetch();
                        });
                      });
                    }}
                    className="button-53"
                  >
                    ดูเพิ่ม
                  </button>
                </div>
              ) : null}
            </div>
          </section>
        </div>
      </>
    );
  }
}
class CarFilterBox extends React.Component {
  render() {
    return (
      <div>
        <CarFilter
          options={this.props.options}
          qs={this.props.qs}
          onSubmit={(data) => {
            let url = Helper.getQueryStringLinkFromObject(data);
            navigate(url);
          }}
        />
      </div>
    );
  }
}
class CarHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      type: window.localStorage.getItem("carsListType") || "grip",
      options: false,
      orderBylist: [
        {
          value: "year,asc",
          text: "ปี เก่า > ใหม่",
        },
        {
          value: "year,desc",
          text: "ปี ใหม่ > เก่า",
        },
        {
          value: "sellingPrice,asc",
          text: "ราคารถ น้อย > มาก",
        },
        {
          value: "sellingPrice,desc",
          text: "ราคารถ มาก > น้อย",
        },
        {
          value: "mile,asc",
          text: "เลขไมล์ น้อย > มาก",
        },
        {
          value: "mile,desc",
          text: "เลขไมล์ มาก > น้อย",
        },
      ],
    };
  }

  componentDidMount() {
    this.fetch();
  }
  async fetch() {
    this.setState({
      options: this.props.options,
    });
  }

  onToggle(type) {
    window.localStorage.setItem("carsListType", type);
    this.setState(
      {
        type: type,
      },
      () => {
        if (type == "list") {
          window.$("#car-list").addClass("list");
        } else {
          window.$("#car-list").removeClass("list");
        }
      }
    );
  }
  onSelectOrderBy(value) {
    const formNode = window.$(".search-cars-container").find("form");
    let data = Helper.getFormInputObject(formNode);
    data.orderBy = value;
    let url = Helper.getQueryStringLinkFromObject(data);
    navigate(url);
    // formNode.trigger('submit');
  }
  render() {
    return (
      <div className="car-filter">
        <div className="container">
          <div className="car-filter-card align-items-center flex-wrap">
            <h3 className="mb-0 pl-2" style={{ fontWeight: "bold" }}>
              ผลการค้นหา
              {/* <small className='mb-0 ml-3 text-muted' style={{ fontWeight: 'bold' }}>{filterTitle} </small> */}
            </h3>
            <div className="right-floated show-lg d-flex">
              <select
                className="form-control btn btn-light active mr-2"
                onChange={(e) => {
                  let value = e.target.value;
                  this.onSelectOrderBy(value);
                }}
                defaultValue={this.props.qs.orderBy || false}
              >
                <option value={""}>เรียงลำดับ</option>
                {this.state.orderBylist.map((item, i) => {
                  return (
                    <option value={item.value} key={i}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className={`btn btn-light ${
                    this.state.type == "grip" ? "active" : null
                  }`}
                  onClick={() => {
                    this.onToggle("grip");
                  }}
                >
                  <i className="las la-grip-horizontal"></i>
                </button>

                <button
                  type="button"
                  className={`btn btn-light ${
                    this.state.type == "list" ? "active" : null
                  }`}
                  onClick={() => {
                    this.onToggle("list");
                  }}
                >
                  <i className="las la-th-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PageHeader extends Component {
  render() {
    let qs = this.props.qs;
    return (
      <section className="cover">
        <div className="container" style={{ position: "relative" }}>
          <div className="row">
            <div className="col-12 slide-info page-inside">
              <h1>
                champ earth <small style={{ color: "#E36C78" }}>motors</small>
              </h1>
              {this.props.title ? (
                <>
                  <h2 className="mb-4">{this.props.title}</h2>
                </>
              ) : null}
              <div className="d-flex flex-wrap">
                {this.props.qs.brand ? (
                  <>
                    {this.props.qs.brand ? (
                      <div
                        className="card custom-select-card d-flex flex-row justify-content-center align-items-center xl mr-2 mb-3 "
                        onClick={() => {
                          let data = { ...this.props.qs };
                          delete data.brand;
                          if (data.model) {
                            delete data.model;
                          }
                          if (data.minor) {
                            delete data.minor;
                          }
                          if (data.submodel) {
                            delete data.submodel;
                          }
                          let url = Helper.getQueryStringLinkFromObject(data);
                          navigate(url);
                        }}
                      >
                        {this.props.qs.brand}{" "}
                        <i className="fa-solid fa-circle-xmark ml-2"></i>
                      </div>
                    ) : null}
                    {this.props.qs.model ? (
                      <div
                        className="card custom-select-card d-flex flex-row justify-content-center align-items-center xl mr-2 mb-3 "
                        onClick={() => {
                          let data = { ...this.props.qs };
                          // delete data.brand
                          if (data.model) {
                            delete data.model;
                          }
                          if (data.minor) {
                            delete data.minor;
                          }
                          if (data.submodel) {
                            delete data.submodel;
                          }
                          let url = Helper.getQueryStringLinkFromObject(data);
                          navigate(url);
                        }}
                      >
                        {this.props.qs.model}{" "}
                        <i className="fa-solid fa-circle-xmark ml-2"></i>
                      </div>
                    ) : null}
                    {this.props.qs.minor ? (
                      <div
                        className="card custom-select-card d-flex flex-row justify-content-center align-items-center xl mr-2 mb-3 "
                        onClick={() => {
                          let data = { ...this.props.qs };
                          if (data.minor) {
                            delete data.minor;
                          }
                          if (data.submodel) {
                            delete data.submodel;
                          }
                          let url = Helper.getQueryStringLinkFromObject(data);
                          navigate(url);
                        }}
                      >
                        {this.props.qs.minor}{" "}
                        <i className="fa-solid fa-circle-xmark ml-2"></i>
                      </div>
                    ) : null}
                    {this.props.qs.submodel ? (
                      <div
                        className="card custom-select-card d-flex flex-row justify-content-center align-items-center xl mr-2 mb-3 "
                        onClick={() => {
                          let data = { ...this.props.qs };
                          if (data.submodel) {
                            delete data.submodel;
                          }
                          let url = Helper.getQueryStringLinkFromObject(data);
                          navigate(url);
                        }}
                      >
                        {this.props.qs.submodel}{" "}
                        <i className="fa-solid fa-circle-xmark ml-2"></i>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
