import React from "react";
import { Helmet } from "react-helmet-async";
export default function SEO({ title, description, name, type, image }) {
  if (!title) title = `Champ Earth Motors`;
  if (!description)
    description = `รับซื้อ - ขาย รถยุโรป มือสอง สภาพดี การันตีประวัติทุกคัน ไมล์แท้ ไม่มีชนหนัก ไม่มีย้อมแมว`;
  if (!name) name = `Champ Earth Motors`;
  if (!type) type = `website`;
  if (!image)
    image = `${window.location.origin}/assets/images/logo-500px-trans.jpg`;
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* <meta property="og:markup_url" content={image} /> */}
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="350" />
      <meta property="og:image:height" content="350" />
      <meta property="og:image:alt" content={title} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={title}></meta>
      {/* End Twitter tags */}
    </Helmet>
  );
}
