import React, { Component } from "react";
import PageHeader from "../../Include/PageHeader";
import TermsInfo from "./../../component/TermsInfo";
import SEO from "../../component/SEO";

export default class Terms extends Component {
  render() {
    return (
      <>
        <SEO />
        <div style={{ position: "relative" }}>
          <PageHeader title={"Terms and Condition"} />
          <section className="about-us pt-0">
            <div className="container mt-3" style={{ position: "relative" }}>
              <TermsInfo />
            </div>
          </section>
        </div>
      </>
    );
  }
}
