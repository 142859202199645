import React, { Component } from "react";
import Helper from "../../Helper";
import PageHeader from "../../Include/PageHeader";
import axios from "axios";
import Loading from "../../component/Loading";
import { Link } from "@reach/router";
import Shared from "../../component/Shared";
import Cube from "../../Animation/Cube";
import { BlogsCard } from "./BlogsList";
import SEO from "../../component/SEO";

const default_limit = 12;
export default class BlogsDetail extends Component {
  constructor() {
    super();

    this.state = {
      page: Helper.getParameterByName("page")
        ? Helper.getParameterByName("page")
        : 1,
      is_loading: true,
      list: [],
      qs: {
        page: Helper.getParameterByName("page")
          ? Helper.getParameterByName("page")
          : 1,
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.uid !== this.props.uid) {
      this.setState(
        {
          is_loading: true,
          data: false,
        },
        () => {
          this.fetch();
        }
      );
    }
  }
  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    let res = await axios.get(`${window.api_host}/blogs/${this.props.uid}`);
    let data = false;
    if (res.data.success) {
      data = res.data.data;
    }

    this.setState({
      data: data,
      is_loading: false,
    });
  }

  render() {
    let data = this.state.data;
    return (
      <>
        <SEO />
        <div style={{ position: "relative" }}>
          <PageHeader title={"Blogs"} />
          {data ? (
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb custom-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/blogs">บทความ</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {data.title}
                  </li>
                </ol>
              </nav>
            </div>
          ) : null}
          <section className="ourscars pt-0">
            <div className="new-cars pt-2">
              <div className="container mt-3" style={{ position: "relative" }}>
                {this.state.is_loading ? (
                  <div style={{ paddingTop: 100, paddingBottom: 120 }}>
                    <Loading />
                  </div>
                ) : null}
                {!this.state.is_loading && data ? (
                  <div style={{ position: "relative", zIndex: 2 }}>
                    <div className="d-flex mb-4">
                      {data.author ? (
                        <p className="mb-0">บมความโดย : {data.author}</p>
                      ) : null}
                      <div style={{ marginLeft: "auto" }}>
                        <p className="mb-0">
                          โพสเมื่อวันที่{" "}
                          {Helper.getDate(data.created, { is_show_time: true })}
                        </p>
                      </div>
                    </div>
                    {data.fileImage?.imagePath ? (
                      <div className="car-carousel-item mb-5 d-flex justify-content-center align-items-center">
                        <a href={data.fileImage?.imagePath} data-fancybox>
                          <img
                            src={data.fileImage?.imagePath}
                            style={{ maxWidth: "100%", margin: "auto" }}
                          />
                        </a>
                      </div>
                    ) : null}
                    {data?.title ? <h3>{data.title}</h3> : null}
                    {data.description ? (
                      <p
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    ) : null}
                    {data.faceBookLink || data.youtubeId ? (
                      <>
                        <hr className="hr-color" />
                        <div className={data.link ? "mb-5" : "mb-0"}>
                          <div className="row justify-content-center align-items-center">
                            {data.faceBookLink ? (
                              <div className="col-12 col-md-6">
                                <iframe
                                  src={`https://www.facebook.com/plugins/video.php?height=314&href=${data.faceBookLink}&show_text=false&width=560&t=0`}
                                  style={{
                                    border: 0,
                                    overflow: "hidden",
                                    width: "100%",
                                    height: 450,
                                  }}
                                  scrolling="no"
                                  frameBorder={0}
                                  allowFullScreen={true}
                                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                />
                              </div>
                            ) : null}
                            {data.youtubeId ? (
                              <div className="col-12 col-md-6">
                                <iframe
                                  style={{ width: "100%", height: 450 }}
                                  src={`https://www.youtube.com/embed/${data.youtubeId}`}
                                  title="YouTube video"
                                  frameBorder={0}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowFullScreen={true}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                    {data.link ? (
                      <div className="mb-5">
                        <a href={data.link} target="_blank">
                          คลิกเพื่อดูรายละเอียด
                        </a>
                      </div>
                    ) : null}
                    <Shared />
                  </div>
                ) : null}
              </div>
              <Cube />
            </div>
            {!this.state.is_loading ? <MoreBlogs /> : null}
          </section>
        </div>
      </>
    );
  }
}

class MoreBlogs extends React.Component {
  constructor() {
    super();

    this.state = {
      is_loading: true,
      list: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    let res = await axios.get(`${window.api_host}/blogs-rand`);
    let list = [...this.state.list];
    if (res.data.success) {
      list = list.concat(res.data.data);
    }
    this.setState(
      {
        is_loading: false,
        list: list,
      },
      () => {
        this.viewMore?.html("ดูเพิ่ม");
        this.viewMore?.removeClass("loading");
      }
    );
  }

  // blogs-rand
  render() {
    return (
      <>
        <div
          className="container pt-5"
          style={{ position: "relative", zIndex: 3 }}
        >
          <h2 className="mb-5">More Blogs</h2>
          {this.state.is_loading ? (
            <div style={{ paddingTop: 100, paddingBottom: 120 }}>
              <Loading />
            </div>
          ) : null}
          {!this.state.is_loading && this.state.list.length > 0 ? (
            <div className="row" id="car-list">
              {this.state.list.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="pl-3 pr-3 mb-3 mt-3">
                      <BlogsCard data={item} />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ) : null}
          {!this.state.is_loading &&
          this.state.pagination &&
          this.state.pagination.now_page < this.state.pagination.pages ? (
            <div className="mt-5 d-flex justify-content-center align-items-center">
              {/* <Link to="/" className='button-53'>ดูทั้งหมด</Link> */}
              <button
                ref={(ref) => {
                  this.viewMore = window.$(ref);
                  this.viewMore.off().on("click", () => {
                    if (this.viewMore.hasClass("loading")) return;
                    this.viewMore.addClass("loading");
                    this.viewMore.html("กำลังโหลดข้อมูล...");
                    let qs = this.state.qs;
                    qs.page = this.state.pagination.now_page + 1;
                    this.setState({ qs: qs }, () => {
                      this.fetch();
                    });
                  });
                }}
                className="button-53"
              >
                ดูเพิ่ม
              </button>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
