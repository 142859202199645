import React, { Component } from "react";
import PageHeader from "../../Include/PageHeader";
import SEO from "../../component/SEO";

export default class ContactUs extends Component {
  render() {
    return (
      <>
        <SEO />
        <div style={{ position: "relative" }}>
          <PageHeader title={"Contact us"} />
          <section className="about-us pt-0">
            <div className="container mt-3" style={{ position: "relative" }}>
              {/* <div className='car-carousel-item mb-5 d-flex justify-content-center align-items-center'>
                            <img src={`${window.location.origin}/assets/images/cover-contact.jpg`} style={{ maxWidth: '100%' }} />
                        </div> */}
              <p className="text-center text-black mb-3">
                ที่อยู่: 41 ซอย วิภาวดีรังสิต 16/9 แขวงจอมพล เขตจตุจักร
                กรุงเทพมหานคร 10900
              </p>
              <div
                className="car-carousel-item mb-3 d-flex justify-content-center align-items-center"
                style={{ border: "3px solid #ccc", height: "auto" }}
              >
                <iframe
                  // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.699006264533!2d100.56524399999999!3d13.797009699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d927297f201%3A0x58e2d09616047b20!2sChampEarth%20Motors!5e0!3m2!1sth!2sth!4v1682084496997!5m2!1sth!2sth"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3872.225149182367!2d100.5820007!3d13.945195499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d927297f201%3A0x58e2d09616047b20!2sChampEarth%20Motors!5e0!3m2!1sth!2sth!4v1685973629393!5m2!1sth!2sth"
                  style={{ border: 0, width: `100%`, height: 300 }}
                  allowfullscreen
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-wrap about-us pt-0 pb-0">
                <a
                  href="tel:0991464646"
                  target={"_blank"}
                  className="d-flex custom-link ml-3 mr-3 mb-3"
                >
                  <i
                    className="fas fa-phone mr-1 d-flex justify-content-center align-items-center "
                    style={{ fontSize: 24 }}
                  ></i>
                  <p className="mb-0">099-146-4646</p>
                </a>
                <a
                  href="https://lin.ee/2BwZQnijS"
                  target={"_blank"}
                  className="d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link"
                >
                  <img
                    src={`${window.location.origin}/assets/images/social/line.png`}
                    style={{ height: 40 }}
                  />
                  <p className="ml-1 mb-0">@Champearthmotors</p>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <a
                  href="https://www.youtube.com/@champearthmotors3741/videos"
                  target={"_blank"}
                  className="d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link"
                >
                  <img
                    src={`${window.location.origin}/assets/images/social/youtube.png`}
                    style={{ height: 40 }}
                  />
                  <p className="ml-1 mb-0">ChampEarth Motors</p>
                </a>
                <a
                  href="https://www.facebook.com/ChampEarthMotors"
                  target={"_blank"}
                  className="d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link"
                >
                  <img
                    src={`${window.location.origin}/assets/images/social/facebook.png`}
                    style={{ height: 40 }}
                  />
                  <p className="ml-1 mb-0">ChampEarth Motors</p>
                </a>
                <a
                  href="https://www.instagram.com/champearth_motors/"
                  target={"_blank"}
                  className="d-flex justify-content-center align-items-center ml-3 mr-3 mb-3 custom-link"
                >
                  <img
                    src={`${window.location.origin}/assets/images/social/instagram.png`}
                    style={{ height: 40 }}
                  />
                  <p className="ml-1 mb-0">ChampEarth_Motors</p>
                </a>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
